.header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.chatLists_user_image {
    height: 5rem;
    width: 5rem;

}

.footer{
    padding: 1.4rem 1.6rem;
    background: white;
}


.shareScreenSection{
    display: flex;
    gap: 2rem;

}
.icon_circle {
    display: flex;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 1rem;
    border-radius: 2rem;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    transition: all 0.4s;
  }

  .icon_red {
  background-color: var(--red);
  padding: 0.7rem;  
}
.icon_circle {
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1rem;
  border-radius: 2rem;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  transition: all 0.4s;
}

  .icon_circle:hover {
    transform: scale(1.1);
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .icon_circle > svg {
    height: 1.4rem;
    width: 1.4rem;
    fill: var(--white);
  }

.main_video_action_btns{
    display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.videoCallSection{
    display: flex;
    gap: 2rem;
    background: #D3D3D3;
    padding: 0.4rem 2rem;
    border-radius: 16px;
    margin-left: -14rem
}

.controlSection{
    display: inline-block;
    cursor: pointer;
}

.iconSection{
    display: flex;
    justify-content: center;
}

.controlsLabel{
    color: #3C0016;
    font-weight: 700;
    display: block;
    font-size: 1.3rem;
    user-select: none;
}

.controlGrid{
    display: flex;
    align-items: end;
    justify-content: space-between;
}