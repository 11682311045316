.skeleton {
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
}

.skeleton::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(240, 240, 241, 0.1), rgba(255, 255, 255, 0.9), rgba(231, 231, 234, 0.1));
  animation: progress 1.5s ease-in-out infinite;
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

/* Table Skeleton */
.table__profile_image {
  height: 3rem;
  width: 3rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.table__row_title {
  height: 1.5rem;
  /* width: 3rem; */
  background-color: rgba(228, 228, 228, 0.445);
}

.table__row_text {
  height: 1rem;
  /* width: 3rem; */
  background-color: rgba(228, 228, 228, 0.445);
}

.form_input__label {
  height: 1rem;
  width: 10rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.form_input__container {
  height: 4rem;
  width: 25rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.button {
  height: 4rem;
  width: 15rem;
  background-color: #0f3f7a15;
}

.buttonCancel {
  height: 1rem;
  width: 10rem;
  background-color: #0f3f7a15;
}

.form_input__text {
  height: 1rem;
  width: 20rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.folder {
  height: 4rem;
  width: 19.5rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.file {
  height: 9.5rem;
  width: 10rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.fileUpload {
  height: 7rem;
  width: 100%;
  background-color: rgba(228, 228, 228, 0.445);
}

.user_card_button {
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.form_data_content_data_attachment {
  border-radius: 1rem;
  height: 3.7rem;
  width: 10rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.chatLists_user_image {
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.chatLists_user_name {
  border-radius: 0.5rem;
  height: 1.4rem;
  width: 25rem;
  background-color: rgba(228, 228, 228, 0.445);
  margin-bottom: 0.5rem;
}

.forms_user_name {
  border-radius: 0.5rem;
  height: 1.4rem;
  width: 80%;
  background-color: rgba(228, 228, 228, 0.445);
  margin-bottom: 0.5rem;
}

.chatLists_user_msg {
  border-radius: 0.5rem;
  height: 1.1rem;
  width: 15rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.chatLists_user_date {
  position: absolute;
  top: 1rem;
  right: 2rem;
  border-radius: 0.5rem;
  height: 1.1rem;
  width: 5rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.chatBox_out_chat {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chatBox_chat_inc_msg {
  border-radius: 1.5rem;
  border-bottom-left-radius: 0;
  padding: 2rem;
  height: 3rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.chatBox_chat_out_msg {
  border-radius: 1.5rem;
  border-bottom-right-radius: 0;
  padding: 2rem;
  height: 3rem;
  background-color: rgba(60, 0, 22, 0.1);
}

.chatBox_chat_time,
.chatBox_chat_time_out {
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  height: 1.1rem;
  width: 5rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.left_content {
  width: 58vw;
  border-radius: 2rem;
  background-color: #0f3f7a15;
  overflow: hidden !important;

  display: flex;
  align-items: center;
  justify-content: center;
}

.left_content p {
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
  color: var(--grey);
  font-weight: 700;
}

.right_content_title {
  height: 1.5rem;
  width: 15rem;
  background-color: rgba(228, 228, 228, 0.445);
}

.right_content_desc {
  margin-top: 0.5rem;
  margin-bottom: 1.2rem;
  height: 1.3rem;
  width: 25rem;
  background-color: rgba(228, 228, 228, 0.445);
}


/* GROUP VIDEO CALL MODULE CSS */

.videoCall_parentContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.videoCall_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  z-index: 999999 !important;
  /* background-color: black; */
  position: absolute;
  left: 0rem;
  top: 0rem;
  /* border-radius: 1rem; */
  /* top: ; */
}

.videoCall_spinner {
  width: 6rem;
  height: 6rem;
  border: 0.7rem solid var(--white);
  border-top: 0.7rem solid var(--primary-color);
  border-radius: 50%;
  animation: videoCall_spin 1s linear infinite;
}

.videoCall_text {
  margin-top: 2rem;
  color: var(--light-grey);
  font-size: 1.8rem;
  font-weight: 600;
}

@keyframes videoCall_spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* add participants skeleton */
/* AddParticipant Skeleton Styles */
.addParticipantSkeletonContainer {
  padding: 1rem 2rem;
  width: 100%;
}

.addParticipantParticipant1 {
  display: flex;
  align-items: center;
  padding: 1.2rem 0;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 0.5rem;
}

.addParticipantProfileSkeleton {
  display: flex;
  margin-right: 1.5rem;
}

.addParticipantAvatarSkeleton {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #f0f0f0;
  flex-shrink: 0;
  animation: fadeAnimation 1.5s infinite ease-in-out;
}

.addParticipantParticipantCard {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.addParticipantInfoSkeleton {
  flex: 1;
}

.addParticipantNameSkeleton {
  width: 12rem;
  height: 1.4rem;
  background: #f0f0f0;
  margin-bottom: 0.8rem;
  border-radius: 0.2rem;
  animation: fadeAnimation 1.5s infinite ease-in-out;
}

.addParticipantEmailSkeleton {
  width: 18rem;
  height: 1.2rem;
  background: #f0f0f0;
  border-radius: 0.2rem;
  animation: fadeAnimation 1.5s infinite ease-in-out;
}

@keyframes fadeAnimation {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}