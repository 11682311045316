.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 3rem;

  overflow: auto;
}
.content {
  display: grid;
  gap: 2rem;
  grid-template-columns: 40rem max-content;
  /* align-items: flex-start; */
}
.pdfContainer {
  grid-column: 2/-1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  /* background-color: var(--white);
  padding: 1.5rem 2rem;
  border-radius: 2rem; */
}
.signatureImage_box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 4rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 1000;
  overflow: hidden;
}
.generated_image {
  /* width: 5rem; */
}
.pdf {
  border-radius: 2rem;
  overflow: hidden;
  /* align-self: center; */
}
.pdfContainer_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  font-weight: 500;
}
.pdfContainer_bottom div {
  display: flex;
  gap: 1rem;
}
.pdfContainer_bottom button {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-family: inherit;
  padding: 0.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
  cursor: pointer;
}
.pdfContainer_bottom button:disabled {
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  cursor: not-allowed;
}
.pdfContainer_bottom button svg {
  height: 2.5rem;
  width: 2.5rem;
  fill: var(--white);
}
.sign {
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--white);
  padding: 1.5rem 2rem;
  border-radius: 2rem;
  height: calc(100% - 4rem);
}
.sign_input {
  font-family: inherit;
  font-size: 1.5rem;
  padding: 1rem 2rem;
  background-color: var(--light-grey);
  border-radius: 1rem;
}
.sign_image {
  position: absolute;
  left: -200vw;
}
.sign_styles {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.sign_styles_title {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--primary-color);
}
.sign_styles div {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(min-content, max-content));
  grid-template-rows: repeat(2, max-content);
  font-size: 1.5rem;
  flex-wrap: wrap;
}
.sign_styles div button {
  align-self: center;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0rem 1rem;
  border-radius: 1rem;
  cursor: pointer;
}
.sign_styles1 {
  font-family: 'Qwitcher Grypen';
  font-size: 3rem;
}
.sign_styles2 {
  font-family: 'Great Vibes';
  font-size: 2.5rem;
}
.sign_styles3 {
  font-family: 'Yellowtail';
  font-size: 2.5rem;
}
.sign_styles4 {
  font-family: 'Playwrite BE WAL';
  font-size: 2rem;
}
.sign_styles5 {
  font-family: 'Playwrite CU';
  font-size: 2rem;
}
.sign_styles6 {
  font-family: 'Sacramento';
  font-size: 2.5rem;
}
.sign_styles_active {
  border: 2px solid var(--light-grey) !important;
  /* background-color: var(--light-grey) !important; */
}
.sign_generate {
  margin-top: 3rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--white);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 1rem 2rem;
  border-radius: 1rem;
  cursor: pointer;
}
.sign_generate:disabled {
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  cursor: not-allowed;
}
