.header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.chatLists_user_image {
    height: 5rem;
    width: 5rem;

}

.headerMain {
    align-items: flex-start;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    padding: 0px 1rem 1rem;
    align-Items: 'flex-start';
    flex: '0'
}