/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');


.headerContainer{
  display: flex;
  top: 19rem;
  left: 3rem;
  justify-content: flex-start;
  position: relative;
}

.logo{
  width: 9rem;
  margin-top: 3rem;
}

.header {
  align-items: center;
  justify-content: space-between;
  background-color: #4d4d4d75;
  padding: 10px 16px;
  width: 21rem;
  border-radius: 5rem;
  height: 6rem;
  margin-left: 3rem;
}

.backButton {
  background-color: #00000040;
  cursor: pointer;
  padding: 1rem;
  border-radius: 3rem;
  border: none;
  margin-left: -1rem;
  margin-top: 1rem;
}

.backButtonIcon {
  width: 2.3rem;
  height: 2rem;
}

.backButton:hover {
  text-decoration: underline;
}

.headerContent {
  flex-grow: 1;
  flex-wrap: wrap;
  margin-left: 1rem;
  text-align: left;
}

.meetingTitle {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}

.hostName {
  margin: 0;
  font-size: 1.2rem;
  color: #c3c3c3;
}

.shareButton {
  background-color: #fff;
  color: white;
  border: none;
  border-radius: 10rem;
  padding: 10px 7px;
  cursor: pointer;
  margin-right: -1rem;
  margin-top: 1rem;
}

.shareButton:hover {
  background-color: #0056b3;
}

.shareIcon{
  width: 3rem;
  height: 2rem;
}

.headerTitle{
  display: flex;
}

.totalParticipants{
  color: #fff;
  font-size: 1.2rem;
  margin-left: 1rem;
  font-family: 'Montserrat', sans-serif;
  word-spacing: 1px;
  font-weight: bold;
}

.participantThumbnailsContainer {
  display: flex;
  align-items: center;
  margin-left: 23rem;
  gap: 0.6rem;
  margin-bottom: 3rem;
}

.thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 14rem;
  overflow: hidden;
  position: relative;
  background-color: #fdfdfdb5;
  border-radius: 4px;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.participantName {
  font-size: 1rem;
  text-align: center;
  display: flex;
  width: 14rem;
}

.extraParticipants {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 14rem;
  background-color: #00000047;
  color: #ffffffb0;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 4px;
}
 */


/* MeetingHeader.module.css */
.headerContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #1a1a1a; /* Match VideoSection background */
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Match VideoSection border style */
  padding: 0 20px;
}

.leftSection {
  display: flex;
  align-items: center;
  flex: 0 0 280px;
}

.groupIcon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #2d7ff9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow to match VideoSection styling */
}

.meetingInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.meetingTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 500; /* Match VideoSection font weight */
}

.meetingSubtitle {
  margin: 4px 0 0 0;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7); /* Use semi-transparent white to match theme */
}

.separator {
  width: 1px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.1); /* Match borders in VideoSection */
  margin: 0 20px;
}

.middleSection {
  display: flex;
  flex: 1;
  gap: 30px;
}

.infoItem {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.participantIcon,
.hostIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1); /* Lighter bg to match theme */
  margin-right: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Simplified icons */
.participantIcon:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
}

.hostIcon:before {
  content: "★"; /* Star symbol for host */
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 15px;
}

.meetingTimer {
  background-color: #2c2c2c; /* Match darker elements in VideoSection */
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 12px;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow like in VideoSection */
}

.connectionStatus {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #4caf50; /* Green like active speaker highlight */
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* .connectionStatus:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 4px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(-45deg);
  top: 5px;
  left: 3px;
} */

@media (max-width: 768px) {
  .headerContainer {
    height: auto;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .leftSection {
    margin-bottom: 10px;
  }

  .separator {
    display: none;
  }

  .middleSection {
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
    width: 100%;
  }

  .rightSection {
    width: 100%;
    justify-content: flex-end;
  }
}

