/* Avatar.module.css */
.avatarContainer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #808080;
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.avatarInner {
    width: 4.8rem;
    height: 4.8rem;
    background-color: #D3D3D3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .avatarInner {
        width: 3.6rem;
        height: 3.6rem;
        font-size: 1.4rem;
    }
}

@media (max-width: 480px) {
    .avatarInner {
        width: 3.2rem;
        height: 3.2rem;
        font-size: 1.2rem;
    }
}