.mainContent {
    margin-top: -16rem;
}

.videoNotesContainer {
    margin-top: 10rem;
}

.gridLayoutWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* border: 2px solid red; */
}

/* GRID LAYOUT CSS */
.gridContainer {
    display: grid;
    gap: 1rem;
    width: 100%;
    padding: 0.2rem;
    transition: all 0.3s ease;
    min-height: 200px;
}

.gridContainer {
    grid-template-columns: 1fr;
}

.gridContainer[data-count="1"] {
    height: 30rem;
}

.gridContainer[data-count="2"] {
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: 2fr; */
    height: 30rem;
}

.gridContainer[data-count="3"] {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
        "small1 featured"
        "small2 featured";
    aspect-ratio: 15/13;
    max-height: 1rem !important;
}

.gridContainer[data-count="3"] > *:nth-child(1) {
    grid-area: small1;
}

.gridContainer[data-count="3"] > *:nth-child(2) {
    grid-area: small2;
}

.gridContainer[data-count="3"] > *:nth-child(3) {
    grid-area: featured;
    height: 100%; 
}

.gridContainer[data-count="3"] > *[data-position="featured"] {
    grid-area: featured;
    height: 100%; 
}

.gridContainer[data-count="4"] {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.gridContainer[data-count="5"],
.gridContainer[data-count="6"] {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.gridContainer[data-count="7"],
.gridContainer[data-count="8"],
.gridContainer[data-count="9"] {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

.gridItem {
    position: relative;
    /* border-radius: 12px; */
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    /* background-color: #2c2c2c; */
    min-height: 180px;
    /* border: 2px solid red; */
}

.gridItem:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.participantInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: blur(5px);
}

.controls {
    display: flex;
    gap: 0.5rem;
}

.controlButton {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0.25rem;
    transition: all 0.2s ease;
}

.controlButton:hover {
    transform: scale(1.1);
}

.pageIndicator {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 0.9rem;
    padding: 0.3rem 0.8rem;
    border-radius: 12px;
    z-index: 10;
    backdrop-filter: blur(5px);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.paginationButtonLeft,
.paginationButtonRight {
    position: absolute;
    top: 27%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.7);
    border: none;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    z-index: 999999;
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.paginationButtonLeft {
    left: -5px;
}

.paginationButtonRight {
    right: -5px;
}

.paginationButtonLeft:hover,
.paginationButtonRight:hover {
    background: rgba(0, 0, 0, 0.8);
    width: 3rem;
    height: 3rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}

.paginationButtonLeft.disabled,
.paginationButtonRight.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.paginationButtonLeft.disabled:hover,
.paginationButtonRight.disabled:hover {
    background: rgba(0, 0, 0, 0.7);
    width: 3rem;
    height: 3rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

/* Video fade-in animation */
@keyframes videoFadeIn {
    from { 
        opacity: 0;
        transform: scale(0.95);
    }
    to { 
        opacity: 1;
        transform: scale(1);
    }
}

.gridItem {
    animation: videoFadeIn 0.3s ease forwards;
}

@media (max-width: 1200px) {
    .gridContainer[data-count="5"],
    .gridContainer[data-count="6"] {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }
    
    .gridContainer[data-count="7"],
    .gridContainer[data-count="8"],
    .gridContainer[data-count="9"] {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .gridContainer {
        gap: 0.75rem;
        padding: 0.5rem;
    }
    
    .gridItem {
        min-height: 160px;
    }
}

@media (max-width: 768px) {
    .gridContainer[data-count="3"] {
        grid-template-areas: 
            "featured featured"
            "small1 small2";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2fr 1fr;
    }

    .gridContainer[data-count="4"],
    .gridContainer[data-count="5"],
    .gridContainer[data-count="6"],
    .gridContainer[data-count="7"],
    .gridContainer[data-count="8"],
    .gridContainer[data-count="9"] {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .pageIndicator {
        font-size: 0.8rem;
        padding: 0.2rem 0.6rem;
    }
    
    .paginationButtonLeft,
    .paginationButtonRight {
        width: 36px;
        height: 36px;
    }
    
    .paginationButtonLeft:hover,
    .paginationButtonRight:hover {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 480px) {
    .gridContainer {
        gap: 0.5rem;
        padding: 0.4rem;
    }

    .gridContainer[data-count="3"] {
        grid-template-areas: 
            "featured"
            "small1"
            "small2";
        grid-template-columns: 1fr;
        grid-template-rows: 2fr 1fr 1fr;
    }
    
    .gridContainer[data-count="2"] {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    
    .gridItem {
        min-height: 140px;
    }
    
    .paginationButtonLeft,
    .paginationButtonRight {
        width: 32px;
        height: 32px;
    }
    
    .paginationButtonLeft:hover,
    .paginationButtonRight:hover {
        width: 36px;
        height: 36px;
    }
    
    .pageIndicator {
        font-size: 0.75rem;
        padding: 0.2rem 0.5rem;
    }
}