.mainContent{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    background: white;
}

.videoNotesContainer {
  display: flex;
  flex: 1; 
  /* border: 2px solid red; */
}