.videoSection {
    flex: 1;
    background-color: #f0f0f0;
    transition: all 0.3s ease;
    overflow: hidden;
  }
  
  .notesSection {
    width: 300px; /* Fixed width for notes section */
    background-color: #e0e0e0;
    transition: all 0.3s ease;
    overflow: hidden;
  }
  
  .fullWidth {
    flex: 1; /* Expand to full width */
    transition: all 0.3s ease;
    width: 100%; /* Ensure full width */
  }
  
  .notesSection.hidden {
    display: none; /* Hide the notes section */
  }