.videoScreen {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  background-color: #121212;
  color: #fff;
  position: relative;
  transition: all 0.3s ease;
}

.panelToggleButton {
  position: absolute;
  left: 31%;
  top: 40%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
  transition: all 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.panelToggleButton:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateY(-50%) scale(1.1);
}

.iconRotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.panelCollapsed .paticipantsScreen {
  transform: translateX(-100%);
  width: 0;
}

.panelCollapsed .userVideoContainer {
  width: 100%;
}

.panelCollapsed .panelToggleButton {
  left: 20px;
}

.paticipantsScreen {
  width: 30%;
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem;
  background-color: #1a1a1a;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  transition: all 0.3s ease;
}

.userVideoContainer {
  position: relative;
  width: 70%;
  /* height: calc(100vh - 7rem); */
  height: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.mainVideoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.userVideo {
  width: 100%;
  height: 93%;
  /* border: 2px solid red; */
  object-fit: contain;
  background-color: #111;
}

.mainVideoName {
  position: absolute;
  bottom: 9rem;
  left: 1.5rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem 1.2rem;
  border-radius: 2rem;
  font-size: 1.1rem;
  font-weight: 500;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  transition: opacity 0.2s ease;
}

.participantContainer {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  overflow: hidden;
  margin-bottom: 0.75rem;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  background-color: #2c2c2c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.participantContainer:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.participantName {
  position: absolute;
  bottom: 0.8rem;
  left: 0.8rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 500;
  z-index: 2;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: opacity 0.2s ease;
}

.participantVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.localVideoContainer {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: calc(100% - 2rem);
  margin: 0 1rem;
  height: 10rem;
  border-radius: 0.8rem;
  overflow: hidden;
  background-color: #2c2c2c;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.participantsWithLocalVideo {
  padding-bottom: 12rem;
}

.localVideoWrapper {
  /* position: fixed;
  bottom: 8.5rem;
  left: 1rem;
  width: 28%; 
  height: 23%;
  aspect-ratio: 25/12;
  border-radius: 0.8rem;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  z-index: 10;
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; */

  /* position: fixed;
  bottom: 8.5rem;
  left: 1rem; */
  position: relative;
  width: 100%;
  /* height: 40rem; */
  /* min-height: 30%; */
  max-height: 50%;
  margin-bottom: 8rem;
  /* padding-bottom: 10%; */
  /* aspect-ratio: 25/12; */
  border-radius: 0.8rem;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  /* z-index: 10; */
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.panelCollapsed .localVideoWrapper {
  left: 1.2rem;
  bottom: 8.5rem;
  width: 20%;
}

.localVideoWrapper:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.localVideoOverlay {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.localVideoName {
  position: absolute;
  bottom: 0.8rem;
  left: 0.8rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.4rem 0.8rem;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 500;
  z-index: 1;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.mediaDevicesContainer {
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
  display: flex;
  gap: 0.5rem;
  z-index: 3;
}

.mediaDevicesContainer button {
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}

.mediaDevicesContainer button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.mediaDevice {
  width: 1.3rem;
  height: 1.3rem;
  fill: white;
}

.crossLine {
  width: 0.2rem;
  height: 100%;
  background-color: #ff4757;
  position: absolute;
  transform: rotate(140deg);
}

.fallbackVideo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #2c3e50, #1a2533);
}

.fallbackMainVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #1a1a1a, #111);
}

.fallbackContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: #ffffff;
}

.fallbackInitial {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: linear-gradient(135deg, #2980b9, #1d5b85);
  color: white;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.fallbackInitialSmallText {
  font-size: 1.5rem;
}

.activeSpeaker {
  border: 2px solid #4caf50 !important;
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.5);
}

@keyframes instantPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
  }

  50% {
    box-shadow: 0 0 0 0.5rem rgba(76, 175, 80, 0.4);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}

.activeSpeaker:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  animation: instantPulse 1.5s infinite;
  pointer-events: none;
}

.screenSharer {
  border: 2px solid #3498db !important;
  box-shadow: 0 0 15px rgba(52, 152, 219, 0.5);
}

.activeSpeaker.screenSharer {
  border: 2px solid #0077ff !important;
  box-shadow: 0 0 15px rgba(0, 119, 255, 0.5);
}

@keyframes screenSharePulse {
  0% {
    box-shadow: 0 0 0 0 rgba(74, 144, 226, 0.5);
  }

  50% {
    box-shadow: 0 0 0 0.5rem rgba(74, 144, 226, 0.3);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(74, 144, 226, 0);
  }
}

.screenSharer:not(.activeSpeaker):after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  animation: screenSharePulse 1.5s infinite;
  pointer-events: none;
}

.paticipantsScreen::-webkit-scrollbar {
  width: 8px;
}

.paticipantsScreen::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.paticipantsScreen::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.paticipantsScreen::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

.groupVideoCall {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 2.5rem;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  z-index: 100;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.centerButtons {
  display: flex;
  justify-content: center;
  gap: 1.8rem;
}

.videoButton {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease, transform 0.1s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.videoButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.videoButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.bgBlue {
  background-color: #007aff;
}

.bgRed {
  background-color: #FF3B30;
}

.bgGrey {
  background-color: #444;
}

.callEndButton {
  background-color: #FF3B30;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 8px rgba(255, 59, 48, 0.4);
}

.callEndButton:hover {
  background-color: #FF2920;
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(255, 59, 48, 0.5);
}

.callEndButton:active {
  transform: scale(1);
  box-shadow: 0 2px 4px rgba(255, 59, 48, 0.3);
}

/* Button icons */
.buttonIcon {
  width: 2.5rem;
  height: 2.5rem;
  fill: white;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.3));
}

.footerLeft {
  display: flex;
  gap: 2rem;
}

.miniPlayerButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s, transform 0.2s;
}

.miniPlayerButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.miniPlayerButton:active {
  transform: translateY(0);
}

.miniPlayerIcon {
  width: 2.2rem;
  height: 2.2rem;
  fill: white;
  margin-bottom: 0.5rem;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.3));
}

.iconName,
.addParticipantsIcon,
.screenShareIcon {
  color: white;
  font-size: 0.9rem;
  text-align: center;
  white-space: nowrap;
  opacity: 0.9;
}

@media (max-width: 1200px) {
  .participantContainer {
    height: 15rem;
  }
}

@media (max-width: 992px) {
  .videoScreen {
    flex-direction: column;
  }

  .userVideoContainer {
    width: 100%;
    height: 60vh;
  }

  .paticipantsScreen {
    width: 100%;
    height: calc(40vh - 7rem);
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    border-right: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 1rem;
  }

  .participantContainer {
    width: calc(33.33% - 0.5rem);
    height: calc(100% - 1rem);
    margin-bottom: 0;
  }

  .localVideoWrapper {
    position: fixed;
    bottom: 8rem;
    left: 1rem;
    width: 25%;
  }

  .participantsWithLocalVideo {
    padding-bottom: 1rem;
  }

  .panelToggleButton {
    top: calc(60vh - 16px);
    left: 50%;
    transform: translateX(-50%);
  }

  .iconRotated {
    transform: rotate(90deg);
  }

  .panelCollapsed .paticipantsScreen {
    transform: translateY(100%);
    height: 0;
  }

  .panelCollapsed .userVideoContainer {
    height: calc(100vh - 7rem);
  }

  .panelCollapsed .panelToggleButton {
    top: calc(100vh - 7rem - 20px);
  }
}

@media (max-width: 768px) {
  .participantContainer {
    width: calc(50% - 0.5rem);
  }

  .localVideoWrapper {
    width: 30%;
  }

  .groupVideoCall {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .footerLeft {
    width: 100%;
    justify-content: center;
  }

  .centerButtons {
    width: 100%;
    gap: 1rem;
  }

  .videoButton,
  .callEndButton {
    width: 3.8rem;
    height: 3.8rem;
  }

  .buttonIcon {
    width: 2rem;
    height: 2rem;
  }
}

@media (max-width: 480px) {
  .participantContainer {
    width: 100%;
    height: 10rem;
  }

  .localVideoWrapper {
    width: 40%;
  }

  .videoButton,
  .callEndButton {
    width: 3.2rem;
    height: 3.2rem;
  }

  .buttonIcon {
    width: 1.8rem;
    height: 1.8rem;
  }

  .miniPlayerButton {
    padding: 0.5rem;
  }
}

.localVideoWrapper .fallbackVideo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #2c3e50, #1a2533);
  border-radius: 0.8rem;
}

.localVideoWrapper .fallbackContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.localVideoWrapper .fallbackInitial {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: linear-gradient(135deg, #2980b9, #1d5b85);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}