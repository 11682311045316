/* Main footer container */
.groupVideoCall {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 2.5rem;
  background: #1a1a1a;;
  backdrop-filter: blur(10px);
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.2);
  z-index: 100;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Center buttons container */
.centerButtons {
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}


.videoButton {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.videoButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25);
}

.videoButton:active {
  transform: translateY(-1px);
}

/* Button states */
.bgBlue {
  background-color: #0A84FF;
  background-image: linear-gradient(to bottom right, #0A84FF, #0060DF);
}

.bgRed {
  background-color: #FF3B30;
  background-image: linear-gradient(to bottom right, #FF453A, #E62C2C);
}

.bgGrey {
  background-color: #3A3A3C;
  background-image: linear-gradient(to bottom, #3A3A3C, #2C2C2E);
}

/* End call button */
.callEndButton {
  background-color: #FF3B30;
  background-image: linear-gradient(to bottom right, #FF453A, #E62C2C);
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 4px 10px rgba(255, 59, 48, 0.3);
}

.callEndButton:hover {
  background-color: #FF2920;
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0 7px 14px rgba(255, 59, 48, 0.4);
}

.callEndButton:active {
  transform: translateY(-1px) scale(1.02);
}

/* Button icons */
.buttonIcon {
  width: 2.5rem;
  height: 2.5rem;
  fill: white;
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
}

/* Crossed line for disabled buttons */
.crossLine {
  width: 0.15rem;
  height: 100%;
  background-color: white;
  position: absolute;
  transform: rotate(140deg);
  border-radius: 1px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

/* Footer left section */
.footerLeft {
  display: flex;
  gap: 2rem;
  margin-left: auto;
}

/* Mini player button styles */
.miniPlayerButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0.6rem;
  border-radius: 0.7rem;
  transition: all 0.2s ease;
  position: relative;
}

.miniPlayerButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.miniPlayerButton:active {
  transform: translateY(-1px);
}

.miniPlayerButton::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #0A84FF;
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.miniPlayerButton:hover::after {
  width: 50%;
}

.miniPlayerIcon {
  width: 2.2rem;
  height: 2.2rem;
  fill: white;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease;
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
}

.miniPlayerButton:hover .miniPlayerIcon {
  transform: scale(1.1);
}

/* Text labels */
.iconName,
.addParticipantsIcon,
.screenShareIcon {
  color: white;
  font-size: 0.85rem;
  text-align: center;
  white-space: nowrap;
  font-weight: 500;
  transition: all 0.2s ease;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.02em;
}

.miniPlayerButton:hover .iconName,
.miniPlayerButton:hover .addParticipantsIcon,
.miniPlayerButton:hover .screenShareIcon {
  color: #0A84FF;
}

/* Add a pulse animation for active states */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(10, 132, 255, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(10, 132, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(10, 132, 255, 0);
  }
}

/* Active class for screen sharing */
.active {
  color: #0A84FF !important;
}

.active .miniPlayerIcon {
  fill: #0A84FF;
  animation: pulse 2s infinite;
}

/* Media queries for responsive design */
@media (max-width: 1024px) {
  .centerButtons {
    position: relative;
    left: 0;
    transform: none;
  }

  .groupVideoCall {
    justify-content: center;
    gap: 2rem;
    padding: 1rem 1.5rem;
  }
}

@media (max-width: 768px) {
  .groupVideoCall {
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
  }

  .footerLeft {
    width: 100%;
    justify-content: center;
    margin-left: 0;
  }

  .centerButtons {
    width: 100%;
    order: -1;
    gap: 1.5rem;
  }

  .videoButton,
  .callEndButton {
    width: 4rem;
    height: 4rem;
  }

  .buttonIcon {
    width: 2.2rem;
    height: 2.2rem;
  }
}

@media (max-width: 480px) {

  .videoButton,
  .callEndButton {
    width: 3.5rem;
    height: 3.5rem;
  }

  .buttonIcon {
    width: 2rem;
    height: 2rem;
  }

  .miniPlayerIcon {
    width: 1.8rem;
    height: 1.8rem;
  }

  .footerLeft {
    gap: 1rem;
  }

  .centerButtons {
    gap: 1rem;
  }
}